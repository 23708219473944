import React, {useState, useEffect} from 'react'
import {Link} from "react-router-dom"
import axios from 'axios'
// import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import phone from "../../assets/newP (3).png";
import hero from "../../assets/hero.svg";
import visa from "../../assets/visa.svg";
import mastercard from "../../assets/mastercard.svg";
import perfect from "../../assets/perfect_money.svg";
import advcash from "../../assets/advcash.svg";
import smile from "../../assets/anim.png"
import justi from "../../assets/giphy.gif"
import dimo from '../../assets/brokerage_PlatformsPicks.png'
import gimo from "../../assets/Blog-1-1.png"
// import f2 from "../../assets/dignity-2.png"
// import f3 from "../../assets/dignity-3.png"
// import f4 from "../../assets/dignity-4.png"

import fImg1 from "../../assets/1.png"
import fImg2 from "../../assets/2.png"
import fImg3 from "../../assets/3.png"
import fImg4 from "../../assets/4.png"
// import authImg from "../../assets/image_authenticate_03@3x.png"
import stepImg from "../../assets/step.png"
import step from "../../assets/step-box.png"
import "./home.scss"
import "./lop.css"
import HotlistsTradingViewWidget from '../../components/hotlist/HotlistsTradingViewWidget ';
import MarketData from 'react-ts-tradingview-widgets';
import { ForexHeatMap } from "react-ts-tradingview-widgets";
import { TickerTape } from "react-ts-tradingview-widgets";
import PublicIcon from '@mui/icons-material/Public';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import opi from "../../assets/opiio.jpg"
import opi4 from "../../assets/opi4.jpg"
import opi3 from "../../assets/opi3.jpg"
import { Button } from 'react-bootstrap';


const Home = () => {
    const url = "https://finnhub.io/api/v1/news?category=general&token=cchg08qad3i4bkk56120";
    const [news, setNews] = useState([]);
    const [noOfNews, setNoOfNews]= useState(4);
    const slice = news.slice(0, noOfNews);

    const [chanks, setChanks] = useState(true)
    const [chanks1, setChanks1] = useState(true)
    const [chanks2, setChanks2] = useState(true)
    const [chanks3, setChanks3] = useState(true)

    const [chank1, setChank1] = useState(true)
    const [chank2, setChank2] = useState(true)
    const [chank3, setChank3] = useState(true)


    useEffect(() => {
          axios.get(url).then((res) => {
            setNews(res.data)})
          .then((err) => {console.log(err);})
      
      },[])
  

     const [weekly, setWeekly] = useState(true)  
      const wikly = ()=>{
        setWeekly(true)
        console.log(weekly)
      }
      const wikly2 = ()=> {
        setWeekly(false)
      }

  return (
    <div className='home' >

        <div className='banner'>     
        <div className='sec' >
         <span className='head' >Smart investments for the future</span>
         <span className='content' >Advanced trading approach leverages sophisticated strategies, 
            state-of-the-art tools, and in-depth market knowledge to enhance 
            decision making, manage risk, and boost potential returns</span>
            <div className='btn-div flex' > 
            <Link to="/register">
             <button className='reg-btn' >Registeration</button>
            </Link>
            <Link to="/register">
             <button className='demo-btn' >Open demo account</button>
            </Link>
            </div>
        </div>
        <div className='first' >
        <img src={phone} alt=''className='phone' />
        <div className='circle' ></div>
        </div>
        </div>
        <div>
          <TickerTape isTransparent="true"/>
          </div>
        <div className='banner-2'>     
        <div className='sec' >
         <span className='head' >Smile at Your future</span>
         <span className='content' >Sign up now and gain access to a world of opportunities. With our user-friendly 
         platform, expert analysis, and educational resources, you'll have the tools you need 
         to take control of your financial future. Whether you're looking to grow your investment 
         portfolio, plan for retirement, or simply learn more about the stock market, MERXCOPY TRADE is 
         here to support you every step of the way.</span>
         <Link to="/235676_certificate" target="_blank"> <Button>Certificate of Incorporation</Button></Link>
        </div>
        <div className='first' >
        <img src={smile} alt=''className='phone' />
        </div>
        
        </div>
               
        
        

        <section class="p-home-m-seo-become-a-trader p-home__section p-home-m-seo-become-a-trader--ltr" style={{marginTop: "50px"}}>
            <div class="page__container">
                <div class="p-home-m-seo-become-a-trader__content-wrap">
                    <h2 class="com-c-title p-home-m-seo-become-a-trader__title" data-trans="become_a_trader_title">About Us
                    </h2>
                    <div class="p-home-m-seo-become-a-trader__content">
                        <div class="p-home-m-seo-become-a-trader__item-wrap" style={{maxWidth: "100%",width: "100%",height: "100%"}}>
                            <div class="p-home-m-seo-become-a-trader__item-wrap-content" style={{display: "flex"}}>
                                <div style={{width: "70%"}}>
                                    <h2 class="com-c-title p-home-m-seo-become-a-trader__item-head" data-trans="become_a_trader_item_1_head">Time to take action with the international
                                    MERXCOPY TRADE broker</h2>
                                    <p class="p-home-m-seo-become-a-trader__item-text" data-trans="become_a_trader_item_1_text_1">
                                    At MERXCOPY TRADE we believe that every should have the 
                                    opportunity to invest in the stock market and achieve financial 
                                    freedom. Founded by a team of passionate traders, we set out with
                                     the mission to democratize stock trading and make it accessible to
                                      individuals from all walks of life. With years of experience in 
                                      the financial industry, 
                                    our experts are dedicated to empowering traders with the
                                     knowledge and tools needed to navigate the complex world of stocks.</p>
                                    <p class="p-home-m-seo-become-a-trader__item-text" data-trans="become_a_trader_item_1_text_2">
                                        From educational broker’s tools, demo accounts, and 24/7 support to your financial
                                        success,
                                        MERXCOPY TRADE works tirelessly to remain at the forefront in trading online.
                                        Join now! Take full
                                        advantage of this online trading leader and make your way into the world of
                                        professional
                                        trading.</p>
                                    <a href="/about-us" target="_blank" class="_633ZZh0WP6 
                                    com-c-button com-c-button_color_accent p-home-c-button-desktop p-home-m-devices__button" 
                                    type="button" style={{minHeight: "unset",
                                    marginRight:"5px",backgroundColor:"#35b94c",borderRadius:"8px",
                                     width:"20%", textAlign:"center", color:"white"}}>more...</a>
                                </div>
                                 
                                <div style={{width: "30%"}}>
                                    <img src={dimo} width="100%" style={{borderRadius: "8px"}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        </section>
        

             <section class="p-home-m-seo-numbers p-home__section p-home-m-seo-numbers--ltr">
            <ul class="p-home-m-seo-numbers__list">
                <li class="p-home-m-seo-numbers-item p-home-m-seo-numbers-item--l">
                    <div class="p-home-m-seo-numbers-item__bg">
                        <div data-gatsby-image-wrapper="" class="gatsby-image-wrapper gatsby-image-wrapper-constrained" style={{height: "100%", width: "100%"}}>
                            <div style={{maxWidth: "394px", display: "block"}}><img alt="" role="presentation" aria-hidden="true" src={{data:"image/svg+xml;charset=utf-8,%3Csvg"}} height='794' 
                            width='394' xmlns='http://www.w3.org/2000/svg' 
                             style={{maxWidth: "100%", display: "block", position: "static"}}/></div>
                            <div aria-hidden="true" data-placeholder-image="" style={{opacity: 0, transition: "opacity 500ms linear 0s", backgroundColor: "transparent", position: "absolute", inset: "0px"}}>
                            </div>
                            <img src="https://gmcstockcopytrading.com/assets/960.webp" alt="planet" style={{objectFit: "cover", opacity: "1"}}/>
                        </div>
                    </div>
                    <div class="p-home-m-seo-numbers-item__content">
                        <div class="p-home-m-seo-numbers-item__icon" > <PublicIcon color='primary' fontSize='large'/>
                        </div>
                        <div class="p-home-m-seo-numbers-item__textcontent">
                            <div class="p-home-m-seo-numbers-item__head"> <span data-trans="numbers_section_countries_head">130+
                                    <br/> countries</span></div>
                            <div class="p-home-m-seo-numbers-item__desc">
                                <p data-trans="numbers_section_countries_desc">We support all, so traders from all over
                                    the world could enjoy and profit anytime</p>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="p-home-m-seo-numbers-item p-home-m-seo-numbers-item--s">
                    <div class="p-home-m-seo-numbers-item__content">
                        <div class="p-home-m-seo-numbers-item__icon"> <PersonOutlineIcon color="primary" fontSize="large"/>
                        </div>
                        <div class="p-home-m-seo-numbers-item__textcontent">
                            <div class="p-home-m-seo-numbers-item__head"> <span data-trans="stats_users_registered">1M+</span>
                            </div>
                            <div class="p-home-m-seo-numbers-item__desc">
                                <p data-trans="numbers_section_accounts_desc">Trader accounts</p>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="p-home-m-seo-numbers-item p-home-m-seo-numbers-item--s">
                    <div class="p-home-m-seo-numbers-item__content">
                        <div class="p-home-m-seo-numbers-item__icon"> <CurrencyExchangeIcon color="primary" fontSize="large"/>
                        </div>
                        <div class="p-home-m-seo-numbers-item__textcontent">
                            <div class="p-home-m-seo-numbers-item__head"> <span data-trans="stats_monthly_transactions">30M+</span></div>
                            <div class="p-home-m-seo-numbers-item__desc">
                                <p data-trans="numbers_section_accounts_transactions_desc">Monthly transactions</p>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="p-home-m-seo-numbers-item p-home-m-seo-numbers-item--s">
                    <div class="p-home-m-seo-numbers-item__content">
                        <div class="p-home-m-seo-numbers-item__icon"> <PriceCheckIcon color="primary" fontSize="large"/>
                        </div>
                        <div class="p-home-m-seo-numbers-item__textcontent">
                            <div class="p-home-m-seo-numbers-item__head"> <span data-trans="stats_withdrawals_per_month">$16M+</span></div>
                            <div class="p-home-m-seo-numbers-item__desc">
                                <p data-trans="numbers_section_accounts_payouts_desc">Average monthly payouts</p>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="p-home-m-seo-numbers-item p-home-m-seo-numbers-item--s">
                    <div class="p-home-m-seo-numbers-item__content">
                        <div class="p-home-m-seo-numbers-item__icon"> <AutoModeIcon color="primary" fontSize="large"/>
                        </div>
                        <div class="p-home-m-seo-numbers-item__textcontent">
                            <div class="p-home-m-seo-numbers-item__head"> <span data-trans="stats_monthly_trade_turnover">$211M</span></div>
                            <div class="p-home-m-seo-numbers-item__desc">
                                <p data-trans="numbers_section_accounts_turnover_desc">Monthly trade turnover</p>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </section>



        <section class="p-home-m-seoblog p-home__section page__container">
            <div class="p-home-m-seoblog__head-wrap">
                <div class="p-home-m-seoblog__head-text-wrap">
                    <h2 class="com-c-title p-home-m-seoblog__title" dataTrans="seoblog_title">Mirror Trading</h2>
                     <p class="com-c-description p-home-m-seoblog__desc" dataTrans="seoblog_desc" style={{lineHeight:"33px"}}>All you need to become a
                                                      trading guru gathered in one place:<br/> education, analytics, video lessons, trading tips, market
                                                      news, and so much more!</p>
                </div>
            </div>
            <div class="p-home-m-seoblog__list-wrap">
                <ul class="p-home-m-seoblog__list" style={{padding: 0}}>
                    <li  class="p-home-m-seoblog__link"  onMouseOver={()=>{setChank1(false)}} onMouseOut={()=>{setChank1(true)}} style={{minHeight: "425px"}}><a href="/register" class="p-home-m-seoblog__link-card">
                            </a><div  class="p-home-m-seoblog__link-card-img-wrap"><a href="/register" class="p-home-m-seoblog__link-card" >
                                <div class="gatsby-image-wrapper gatsby-image-wrapper-constrained" style={{width: "100%"}}>
                                    <img src="https://gmcstockcopytrading.com/assets/pexels-anna-nekrashevich-6801647.jpg"/>

                                </div>
                                <div  class="p-home-m-seoblog__link-card-title-wrap"><span class="p-home-m-seoblog__link-card-title">Copy Option Trading
                                        <br/> Copy trading is a type of trading where you copy the trades performed by
                                        another, more experienced trader. It can be manual, semi-automatic or fully
                                        automatic.</span></div>
                        </a></div></li>
                    <li  className="p-home-m-seoblog__link"><a href="/register" className="p-home-m-seoblog__link-card">
                            <div className="p-home-m-seoblog__link-card-img-wrap">
                                <div className="gatsby-image-wrapper gatsby-image-wrapper-constrained" style={{width: "100%"}}>
                                    <img src="https://gmcstockcopytrading.com/assets/pexels-george-morina-4960396.jpg"/>
                                </div>
                            </div>
                            <div  class="p-home-m-seoblog__link-card-title-wrap"><span class="p-home-m-seoblog__link-card-title">Advance Trading <br/>
                                    The trade entry and exit rules can be based on simple conditions such as a moving
                                    average crossover or they can be complicated strategies that require a comprehensive
                                    understanding of the programming language specific to the user's trading platform.
                                </span></div>
                        </a></li>
                    <li className="p-home-m-seoblog__link"><a href="/software" className="p-home-m-seoblog__link-card">
                            <div className="p-home-m-seoblog__link-card-img-wrap">
                                <div className="gatsby-image-wrapper gatsby-image-wrapper-constrained" style={{width: "100%"}}>
                                    <img src="https://gmcstockcopytrading.com/assets/pexels-alphatradezone-5831671.jpg"/>


                                </div>
                            </div>
                            <div class="p-home-m-seoblog__link-card-title-wrap"><span class="p-home-m-seoblog__link-card-title">Cryptocurrency Market: <br/> What Will
                                    Happen by
                                    2030?</span></div>
                        </a></li>
                </ul>
            </div>
        </section>




        <section class="p-home-m-seo-become-a-trader p-home__section p-home-m-seo-become-a-trader--ltr" 
        style={{paddingBottom:"6px", paddingTop:"31px"}}>
        <div className='page__container'>
            <h2 className="head" style={{marginTop: "64px", marginBottom:"2rem", 
            fontFamily:"Sansita, sans-serif"}}>Some of the asset class we offer</h2>
            <div className='' style={{grid: "1fr 1fr/1fr 1fr", marginTop: "54px",
             maxWidth: "1228px",width:"100%", gridGap: "20px",display: "grid", gap: "20px"}}>
              
             <div  style={{}}>
             <img src={opi} style={{height:"35%",borderRadius:"10px", width:"100%",bottom:0, borderTopRadius:"5px", marginBottom:"-2rem"}}/>
             <Link to='/register'>             <p className="about_us__modern-item" dir="auto" onMouseOver={()=>{setChanks3(false)}} onMouseOut={()=>{setChanks3(true)}}
                   style={chanks3 ? {width: "100%", height:"240px"
                   ,paddingTop:"0px",background: "#212926"} : {width: "100%", height:"240px",paddingTop:"0px",background: "#35b94c"}}>
            <h3 style={{marginBottom: "8px",marginTop:".5rem",
            fontStyle:"italic",
            fontFamily:"Sansita, sans-serif",
                 fontStyle: "normal",
                 fontWeight: 700,
                 fontSize: "24px",
                 lineHeight: "30px",
                  color: "#fff"}}>
                    
                    Futures Trading:</h3>
                      Dive into the exciting world of
                                futures trading at
                                MERXCOPY TRADE Trade contracts on various commodities, indices, and currencies, and take
                                advantage of market volatility and price movements to potentially generate profits. With our
                                advanced trading tools and comprehensive market analysis, you'll have the resources to
                                navigate the futures market with confidence.
                    <div className='lean' style={{marginTop: "0.8rem"}}> <Link style={{color:"green"}} to="/futures"> Learn More ...</Link> </div>
                    </p>
                     </Link>
                  </div>

                  <div>
                  <img src={gimo} style={{height:"35%",borderRadius:"10px", width:"100%",bottom:0, borderTopRadius:"5px", marginBottom:"-2rem"}}/>
                  <Link to='/register'>             
                  <p className="about_us__modern-item" dir="auto" onMouseOver={()=>{setChanks2(false)}} onMouseOut={()=>{setChanks2(true)}}
                   style={chanks2 ? {width: "100%", height:"240px"
                   ,paddingTop:"0px",background: "#212926"} : {width: "100%", height:"240px",paddingTop:"0px",background: "#35b94c"}}>
            <h3 style={{marginBottom: "8px",marginTop:".5rem",
            fontStyle:"italic",
            fontFamily:"Sansita, sans-serif",
                 fontStyle: "normal",
                 fontWeight: 700,
                 fontSize: "24px",
                 lineHeight: "30px",
                  color: "#fff"}}>
                     Stock Trading:</h3>
                
                     Unlock the potential of the stock
                                market with MERXCOPY TRADE
                                Whether you're a seasoned investor or a beginner, our platform offers a diverse selection of
                                stocks from major exchanges worldwide. Stay informed with real-time data, conduct in-depth
                                research, and make informed investment decisions. Build a well-rounded portfolio and
                                capitalize on market opportunities.
                    <div className='lean' style={{marginTop: "0.8rem"}}> <Link style={{color:"green"}} to="/stock"> Learn More ...  </Link> </div>
                    </p>
                    </Link>
                    </div>
                  
                 
                    <div>
                  <img src={opi4} style={{height:"40%",borderRadius:"10px", width:"100%",bottom:0, borderTopRadius:"5px", marginBottom:"-2rem"}}/>
                  <Link to='/register'>             
                  <p className="about_us__modern-item" dir="auto" onMouseOver={()=>{setChanks1(false)}} onMouseOut={()=>{setChanks1(true)}}
                   style={chanks1 ? {width: "100%", height:"240px"
                   ,paddingTop:"0px",background: "#212926"} : {width: "100%", height:"240px",paddingTop:"0px",background: "#35b94c"}}>
            <h3 style={{marginBottom: "8px",marginTop:".5rem",
            fontStyle:"italic",
            fontFamily:"Sansita, sans-serif",
                 fontStyle: "normal",
                 fontWeight: 700,
                 fontSize: "24px",
                 lineHeight: "30px",
                  color: "#fff"}}>
                     Forex Trading:</h3>

                          Experience the largest financial
                                market in the world with
                                forex trading at MERXCOPY TRADE Trade major, minor, and exotic currency pairs, and take
                                advantage of the 24/5 market hours. Leverage our advanced trading platform, access real-time
                                data, and utilize our educational resources to enhance your forex trading skills. 
                    <div style={{marginTop: ".8rem"}}> <Link style={{color:"green"}} to="forex"> Learn More ...</Link>  </div>
                    </p>
                    </Link>
                    </div>

                    <div>
                  <img src={opi3} style={{height:"40%",borderRadius:"10px", width:"100%",bottom:0, borderTopRadius:"5px", marginBottom:"-2rem"}}/>
                  <Link to='/register'>             
                  <p className="about_us__modern-item" dir="auto"  onMouseOver={()=>{setChanks(false)}} onMouseOut={()=>{setChanks(true)}}
                  style={chanks ? {width: "100%", height:"240px"
                  ,paddingTop:"0px",background: "#212926"} : {width: "100%", height:"240px",paddingTop:"0px",background: "#35b94c"}}>
            <h3 style={{marginBottom: "8px",marginTop:".5rem",
            fontStyle:"italic",
            fontFamily:"Sansita, sans-serif",
                 fontStyle: "normal",
                 fontWeight: 700,
                 fontSize: "24px",
                 lineHeight: "30px",
                  color: "#fff"}}>
                  Oil and Gas Stock Trading:</h3>

                       Explore the dynamic energy sector
                                with oil and gas stock
                                trading at MERXCOPY TRADE Trade stocks of major industry players, exploration companies,
                                and
                                service providers. Stay updated with market insights, industry trends, and expert analysis
                                to make informed trading decisions. Take advantage of the potential growth and volatility in
                                the oil and gas industry.
                    <div className='lean' style={{marginTop: ".8rem"}}> <Link style={{color:"green"}} to="/commodites"> Learn More ... </Link></div>
                </p>
                </Link>
                </div>
               </div>
        </div>
       </section>

       <section class="p-home-m-seo-become-a-trader p-home__section p-home-m-seo-become-a-trader--ltr" style={{paddingBottom:"6px", paddingTop:"1rem"}}>
        <div className='page__container'>
            <h2 className="about_us__modern-title" style={{marginTop: "64px", maxWidth:"633px", textAlign:"start"}}>Enroll yourself on merxcopytrade </h2>
            <div className="about_us__modern-items" style={{borderBottom:"none", display: "grid",gridTemplateColumns: "repeat(2, 1fr)", gap: "1rem"}}>
                <p className="about_us__modern-item" dir="auto" style={{width: "100%",fontWeight:"bolder",
                 height:"100%", background:"linear-gradient(137.45deg, rgb(67 162 112) 7.42%, rgb(14, 15, 18) 104.16%)"}}>
                    <p style={{width: "100%",fontWeight:"bolder", fontSize:"1.3rem", marginBottom:"3rem"}}>CLEAR INVESTMENT PROCESSES</p>
                Extensive Market Coverage: MERXCOPY TRADE offers an extensive range of stocks across various markets,
                    including major exchanges and emerging markets. Gain access to a diverse selection of companies,
                    sectors, and industries, allowing you to create a well-balanced and diversified portfolio.</p>
                <p className="about_us__modern-item" dir="auto" style={{width: "100%", color:"black",fontWeight:"bolder", backgroundColor:"#ffffff8a"}}>
                <p style={{width: "100%",fontWeight:"bolder", fontSize:"1.3rem", marginBottom:"3rem"}}>ESG INTEGRATION</p>
                As a signatory of the United Nations-supported Principles for Responsible
                 Investment (UN PRI) initiative, we're committed to investing responsibly and 
                supported by a global team of dedicated 
                ESG specialists whose recommendations help 
                shape the investment process.
                    top of the market movements.</p>
                <p className="about_us__modern-item" dir="auto" style={{width: "100%",
                 height:"100%",fontWeight:"bolder", background:"linear-gradient(137.45deg, rgb(67 162 112) 7.42%, rgb(14, 15, 18) 104.16%)"}}>
                <p style={{width: "100%",fontWeight:"bolder", fontSize:"1.3rem", marginBottom:"3rem"}}>ROBUST OVERSIGHT</p>
                Portfolio risk management is supplemented by our independent risk
                 and quantitative analytics team—which partners with investment teams to 
                 measure behavioral biases and other risks but reports to
                 senior investment management—and an operational risk management function 
                 that assesses risk across the complex.</p>
                <p className="about_us__modern-item" dir="auto" style={{width: "100%", color:"black",fontWeight:"bolder", backgroundColor:"#ffffff8a"}}>
                <p style={{width: "100%",fontWeight:"bolder", fontSize:"1.3rem", marginBottom:"3rem"}}>HIGH-CONVICTION, RISK-AWARE PORTFOLIOS</p>
                Our focus on proprietary, security-level research allows us to
                 build high-conviction, differentiated portfolios.
                 Our risk management processes provide valuable insight to 
                 help our teams understand potential outcomes.
                </p>
            </div>
            <div style={{textAlign:"center"}}>
                <Link to="/register">
            <button style={{width:"13rem", padding:"1.3rem", borderRadius:"7px", 
            backgroundColor:"#35b94c", border:"none",color:"white"}}>Get Started</button>
            </Link>
            </div>
        </div>
        </section>
           
        <section class="p-home-m-seodevices p-home__section" >
            <div class="page__container">
        <div class="p-home-m-seodevices__head-wrap">
                    <div class="p-home-m-seodevices__head-text-wrap">
                        <h2 class="com-c-title p-home-m-seodevices__title" data-trans="seodevices_title">Trade on any
                            device
                        </h2>
                        <p class="com-c-description p-home-m-seodevices__desc" data-trans="seodevices_desc">Achieve your
                            financial ambitions on any device, anywhere, anytime</p>
                    </div>
                </div>
       
        <ForexHeatMap className="mop" colorTheme="dark" isTransparent="yes" width="100%"
         ></ForexHeatMap>
        
        </div>
        </section>

        <section class="p-home-m-new-features p-home__section" style={{display:"flex", justifyContent:"center"}}>
        <div class="page__container">
                <h2 class="com-c-title p-home-m-new-features__title" data-trans="home_new_features_title">Trading at your
                    fingertips</h2>
                <p class="com-c-description p-home-m-new-features__desc" data-trans="home_new_features_desc">New features,
                    latest webinars and more...</p>
                <div class="p-home-m-new-features-c-card p-home-m-new-features-c-card--ltr p-home-m-new-features-c-card_color_positive p-home-m-new-features-c-card_type_experience p-home-m-new-features__card"
                 style={{backgroundColor: "#ffffff", borderRadius:"10px"}}>
                    <div style={{display: "flex",padding:"20px"}}>
                        <div style={{width: "50%",color:"#000"}}>
                            <h3>
                                Powerful Trading Platforms to help you succeed
                            </h3>
                            <p className='pee'>
                                Clients in over 200 countries and territories trade stocks, options, futures, currencies,
                                bonds, funds and more on 150 global markets from a single unified platform.
                            </p>
                            <p className='pee'>
                                Spot opportunities and calibrate complete portfolio performance. Keep your performance track
                                record with PortfolioAnalyst inception reporting and historical aggregation at no cost.
                            </p>
                            <p  className='pee'>
                                Our mission is to bring advanced portfolio analytics to everyone who needs them – both
                                professionals and individuals. The best way to do that is to offer them at no cost, with no
                                strings.
                            </p>
                        </div>
                        <div style={{width: "50%",display:"flex",alignItems:"center"}}>
                            <img style={{width: "100%",borderRadius:"8px"}} src="https://gmcstockcopytrading.com/assets/pexels-burak-the-weekender-187041.jpg"/>
                        </div>
                    </div>
                </div>
            </div>
            </section>


            <section class="p-home-m-seo-become-a-trader p-home__section p-home-m-seo-become-a-trader--ltr">
            <div class="page__container">
                <div>
                    <div class="p-home-m-seo-become-a-trader__item-wrap-content" style={{color:"white"}}>
                        <h2 class="com-c-title p-home-m-seo-become-a-trader__item-head" data-trans="become_a_trader_item_1_head">Chambers level</h2>
                        <p class="p-home-m-seo-become-a-trader__item-text" data-trans="become_a_trader_item_1_text_1">
                            Chambers level, is the target level for an expert trader that is affiliated with us to get up to
                            in a year. This level comprises of 50+ members only for further information about this level
                            will be discussed with your trader.
                            </p><hr/>
                            Benefits:<br/>
                            Streaming live with your trader
                            Getting your portfolio up to Millions
                            Learning from other chamber’s investors across the countries
                            <hr/>
                            Rules:<br/>
                            You will need to get your account up to the requirements for you to participate on the chambers
                            level, more details will be explained to you by trader due to every traders have their yearly
                            target level. Add this at the down
                        <p></p>
                    </div>
                </div>
            </div>
        </section>


     {/* plans package */}
        <div class="wrapper"  >
    <fieldset style={{ color:"white"}}>
        <div>
            <label>
                <input type="radio" name="radio" onClick={wikly} checked/>
                <span>Standard</span>
            </label>
            {/* <label>
                <input type="radio" name="radio" onclick="selectPlan('monthly')"/>
                <span>Advanced</span>
            </label>
            <label>
                <input type="radio" name="radio" onclick="selectPlan('nfp')"/>
                <span>NFP</span>
            </label> */}
            <label>
                <input type="radio" name="radio" onClick={wikly2}/>
                <span>CHEMBERS</span>
            </label>
        </div>
    </fieldset><br/>
    <div class="price-tab weekly active animate__animated animate__fadeIn" style={weekly ? {display:"block"} : {display:"none"}}>
        <div class="pricing-table">
            <div class="pricing-box-2">
                <h2>TIER I PLAN </h2>
                <span class="price">40% - 45% PIPS</span>
                
                <span class="pricing-table-divider"></span>
                <a class="btn" href="/register"><b>Get Started</b></a>
                <span class="pricing-table-divider"></span>
                <ul>
                    <li>minimum:$50,000</li>
                    <li>maximum:UNLIMITED</li>
                    <li>10% Trade Commission</li>
                    <li>24/7 active support</li>
                </ul>
            </div>
            <div class="pricing-box-2">
                <h2>TIER II PLAN </h2>
                <span class="price">35% - 40% PIPS</span>
                
                <span class="pricing-table-divider"></span>
                <a class="btn" href="/register"><b>Get Started</b></a>
                <span class="pricing-table-divider"></span>
                <ul>
                    <li>minimum:$20,000</li>
                    <li>maximum:$49,999</li>
                    <li>10% Trade Commission</li>
                    <li>24/7 active support</li>
                </ul>
            </div>
            <div class="pricing-box-2">
                <h2>TIER III PLAN </h2>
                <span class="price">30% - 35% PIPS</span>
                
                <span class="pricing-table-divider"></span>
                <a class="btn" href="/register"><b>Get Started</b></a>
                <span class="pricing-table-divider"></span>
                <ul>
                    <li>minimum:$10,000</li>
                    <li>maximum:$19,999</li>
                    <li>10% Trade Commission</li>
                    <li>24/7 active support</li>
                </ul>
            </div>
            
        </div>
    </div>



    <div class="price-tab btc"  style={weekly ? {display:"none"} : {diplay:"block"}}>
        <div class="pricing-table">

            <div class="pricing-box-2">
                <h2>PRO CHEMBERS</h2>
                <span class="price">90% - 95% PIPS</span>
                
                <span class="pricing-table-divider"></span>
                <a class="btn" href="/register"><b>Get Started</b></a>
                <span class="pricing-table-divider"></span>
                <ul>
                    <li>30+ BTC</li>
                    <li>10% Trade Commission</li>
                    <li>24/7 active support</li>
                </ul>
            </div>

            <div class="pricing-box-2">
                <h2>STANDARD CHEMBERS</h2>
                <span class="price">70% - 75% PIPS</span>
                
                <span class="pricing-table-divider"></span>
                <a class="btn" href="/register"><b>Get Started</b></a>
                <span class="pricing-table-divider"></span>
                <ul>
                    <li>5 - 14.9 BTC</li>
                    <li>10% Trade Commission</li>
                    <li>24/7 active support</li>
                </ul>
            </div>
            <div class="pricing-box-2">
                <h2>PREMIUM CHEMBERS</h2>
                <span class="price">80% - 85% PIPS</span>
                
                <span class="pricing-table-divider"></span>
                <a class="btn" href="/register"><b>Get Started</b></a>
                <span class="pricing-table-divider"></span>
                <ul>
                    <li>15 - 29.9 BTC</li>
                    <li>10% Trade Commission</li>
                    <li>24/7 active support</li>
                </ul>
            </div>
            <div class="pricing-box-2">
                <h2>BASIC CHEMBERS</h2>
                <span class="price">65% - 70% PIPS</span>
                
                <span class="pricing-table-divider"></span>
                <a class="btn" href="/register"><b>Get Started</b></a>
                <span class="pricing-table-divider"></span>
                <ul>
                    <li>1 - 1.5 BTC</li>
                    <li>10% Trade Commission</li>
                    <li>24/7 active support</li>
                </ul>
            </div>
        </div>
    </div>



   </div>


   
           

{/* tier2 */}




        <div className='second' >
            <div className='flex space logos' >
                 <img className='brand-logos' src={visa} alt='' />
                 <img className='brand-logos' src={mastercard} alt='' />
                 <img className='brand-logos' src={advcash} alt='' />
                 <img className='brand-logos' src={perfect} alt='' />
            </div>

            <div className='features' >
                <div className='features-1 col' >
                    <div className="col f-fcol" >
                        <img className="f-images" src={fImg1} alt='' />
                        <div className='col f-col'  >
                            <span className="f-header" >Well regulated</span>
                            <span className='f-content' >Licensed and regulated by relevant regulatory bodies</span>
                        </div>
                    </div>
                    <div className="col f-fcol" >
                        <img className="f-images" src={fImg2} alt='' />
                        <div className='col f-col' >
                            <span className="f-header" >24/7 customer service</span>
                            <span className='f-content' >Our professional customer service team is online 24 hours a day. We are sincere in helping you solve your trading queries and needs</span>
                        </div>
                    </div>
                    
                </div>

                <div className='features-1 col'  >
                <div className="col f-fcol" >
                        <img className="f-images" src={fImg3} alt='' />
                        <div className='col f-col' >
                            <span className="f-header" >Risk management</span>
                            <span className='f-content' >Get to know more about the free risk management tools Mitrade offers to help you keep your loss.</span>
                        </div>
                    </div>
                    <div className="col f-fcol" >
                        <img className="f-images" src={fImg4} alt='' />
                        <div className='col f-col' >
                            <span className="f-header" > Trading strategies</span>
                            <span className='f-content' >Trading strategies that help you grab the market trends and spot trading opportunities effortlessly.</span>
                        </div>
                    </div>
                </div>
                
            </div>

            <div className='step-trading' >
                <div className='col first' >
                <span className='h2s' >Start Trading in 3 Easy Steps!</span>
                <div className='flex first-div1' >
                <img className='step-img' src={step} alt='' />
                <div className='col first-div2' >
                    <div className='col  ' >
                        <span className='h3s' >1. Registeration</span>
                        <span className='px' >Complete your account application</span>
                    </div>
                    <div className='col' >
                        <span className='h3s'>2. Deposit funds</span>
                        <span className='px' >Select from a wide range of payment methods</span>
                    </div>
                    <div className='col' >
                        <span className='h3s'>3. Start Trading</span>
                        <span className='px' >A world of opportunities awaits</span>
                    </div>

                   <Link to="/register"> <button className='reg-btnn' >Open live account</button></Link>
                    
                </div>
                </div>
                </div>
                <div className='sec' >
                    <img className='stepImg' src={stepImg} alt='' />
                </div>
            </div>

           
                   


            
        </div>
    </div>
  )
}

export default Home