import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Container,Col,
    Modal,
    ModalBody,
    ModalHeader,
    ModalTitle,
    ModalFooter,
     Row,Card,CardBody,CardTitle,FormControl,Form,FormGroup, InputGroup, CardHeader } from 'react-bootstrap';
import './pol.css'
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PaymentIcon from '@mui/icons-material/Payment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { useState, useRef } from 'react';
import { makeRequest } from '../../axios';
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/merxologo.png"




export default function Kpackps() {
   
    
    const timy = new Date()
    const limo = timy.toDateString()
    const [sety, setSety] = useState(false)


const [selectedComponent, setSelectedComponent] = useState('method');
   const done = ()=> {
    navigate('/dashboard')
   }

   const navigate = useNavigate();

    return (

      <div fluid className=''  style={{marginTop:"5rem", display:"flex", justifyContent:"center"}}>
      
        <div >
        
                            
                           <Card.Header as="h4" size="bg" style={{color:"whitesmoke", textAlign:"center"}}>
                          <p style={{marginBottom:"5rem"}}><img src={logo}/></p>
                           YOUR WITHDRAWAL IS BEING PROCESSED THANK YOU FOR YOUR PATIENCE.
                           </Card.Header>
                             <hr style={{color:"white", marginBottom:0}} />
                            <Card.Body style={{backgroundColor:"transparent", borderTop: "2px solid white", margin:"-.1rem"}}>
                            <Card.Title style={{display:"flex", justifyContent:"space-between", color:"whitesmoke", textAlign:"center"}}>
                              
                            </Card.Title> 
                            
                            
                            </Card.Body> 
                
             </div>
             
           </div>
          
    
    )
}