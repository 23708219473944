import './kfut.css'
import bogo from '../../about/img/lopi.svg'
import { Link } from 'react-router-dom'


export default function Kforex(){
    return (
      
        <div >
        <div style={{padding:"20px"}}>
            <div className="about_us__quotex__container">
                <div className="about_us__quotex" dir="auto">
                    <h1 style={{color:"white"}}>
                        Forex Trading </h1>
    
                    <h3 className="about_us__quotex-title">Welcome to Forex Trading at MERXCOPY TRADE</h3>
    
                    <p className="about_us__quotex-text">
                        Experience the largest and most liquid financial market in the world with MERXCOPY TRADE's forex trading
                        platform. Whether you're a seasoned forex trader or a beginner looking to enter the exciting world of
                        currency trading, we provide you with the tools, resources, and support to participate in the forex
                        market and seize global investment opportunities. </p>
                </div>
    
                <img className="about_us__quotex__image" style={{width: "300px", opacity: 0.7}} src={require("../../about/img/brokerage_PlatformsPicks.png")} alt="about-us" 
                 />
            </div>
    
            <div className="about_us__desires-container">
                <img className="about_us__desires-image" src={require ("../../about/img/about-us-desires.png")} alt="desires" />

                <div className="about_us__desires" dir="auto">
                    <h2 className="about_us__desires-title">Explore the world of futures.
                    </h2>
                    <p className="about_us__desires-text">
                        Forex trading involves leverage, and we provide you with the necessary risk management tools to protect
                        your capital. Set stop-loss and take-profit orders, manage your margin requirements, and implement risk
                        management strategies to ensure responsible trading practices.</p>
                    <p className="about_us__desires-text">
                        Our customer support team is committed to providing exceptional assistance to forex traders. Whether you
                        have questions about currency pairs, order execution, or trading platforms, our knowledgeable support
                        staff is available 24/7 to address your concerns.
                    </p>
                </div>
            </div>
    
            <div className="about_us__modern">
                <h2 className="about_us__modern-title" style={{marginTop: "64px"}}>Why Trade Forex with MERXCOPY TRADE?</h2>
                <div className="about_us__modern-items">
                    <p className="about_us__modern-item" dir="auto">
                        Global Market Access: MERXCOPY TRADE gives you access to a wide range of currency pairs, allowing you to
                        trade major, minor, and exotic currencies. Take advantage of the 24/5 nature of the forex market and
                        capitalize on global economic events and news that impact currency values.</p>
                    <p className="about_us__modern-item" dir="auto">
                        Advanced Trading Platform: Our forex trading platform is designed to meet the demands of active traders.
                        Benefit from real-time pricing, lightning-fast trade execution, and advanced order types. Utilize our
                        comprehensive charting tools, technical indicators, and risk management features to optimize your forex
                        trading strategies.</p>
                    <p className="about_us__modern-item" dir="auto">
                        Educational Resources: Expand your knowledge of forex trading with MERXCOPY TRADE's educational resources.
                        Access our tutorials, webinars, and articles that cover fundamental concepts, trading strategies, and
                        risk management techniques. Stay informed about the factors influencing currency markets and sharpen
                        your trading skills.</p>
                    <p className="about_us__modern-item" dir="auto">
                        Market Analysis: Make informed trading decisions with the help of our forex market analysis. Stay
                        updated on major economic indicators, central bank decisions, and geopolitical events that impact
                        currency movements. Our research and analysis tools empower you to identify potential trading
                        opportunities.
                    </p>
                </div>
            </div>
    
            <div className="main__innovation-container">
                <img style={{width: "100%",height: "350px"}} src={bogo} alt="about-us" />
    
                <h2 className="main__innovation-title">MERXCOPY TRADE: Innovation Platform<br/> Digital Asset Trading</h2>
    
                <div className="main__innovation-button">
                    <a className="main__innovation-real" href="/register">
                        <svg width="25" height="25" viewbox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.119244 13.2959L0.638568 13.301C0.942373 13.3039 1.23105 13.1686 1.42312 12.9332L4.52519 9.13133C5.00616 8.54184 5.94312 8.68406 6.22751 9.38972L8.56812 15.1975C8.91985 16.0703 10.1726 16.0184 10.4509 15.1195L13.7379 4.5027C13.9688 3.757 14.9269 3.55157 15.4432 4.13706L18.4078 7.49889C18.792 7.93463 19.4655 7.95233 19.8721 7.53739L23.6192 3.7134M-0.280762 21.3057C0.119238 21.3057 17.9526 21.3057 24.6192 21.3057" stroke="white" stroke-width="2.7"></path>
                        </svg>
    
                        Open real account </a>
    
                    <a className="main__innovation-demo" href="/login">
                        <svg width="33" height="33" viewbox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.4271 13.4714L16.7916 8.90616C16.9217 8.8617 17.0623 8.85798 17.1946 8.89548L30.2759 12.6029C30.9069 12.7818 30.9395 13.6641 30.3233 13.8889L17.2009 18.6775C17.0649 18.7271 16.9165 18.7318 16.7776 18.6908L3.45435 14.7601C2.82191 14.5735 2.80312 13.6846 3.4271 13.4714Z" stroke="#3690F2" stroke-width="2.70521"></path>
                            <path d="M8.36377 16.2461V22.665C8.36377 24.355 9.73378 25.725 11.4238 25.725H22.2772C23.9672 25.725 25.3372 24.355 25.3372 22.665V16.2461" stroke="#3690F2" stroke-width="2.70521"></path>
                            <path d="M30.7695 13.2266L30.7695 20.987" stroke="#3690F2" stroke-width="2.70521"></path>
                        </svg>
    
                        Sign In </a>
                </div>
            </div>
        </div>
        </div>

    )
}