import { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/merxologo.png"
import "./register.scss";

import { AuthContext } from "../../authcontext/authContext";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {  Container,Col,
    Modal,
    ModalBody,
    ModalHeader,
    ModalTitle,
    ModalFooter,
     Row,Card,CardBody,CardTitle,FormControl,
     FormGroup, InputGroup,FormSelect,
      CardHeader, FormLabel } from 'react-bootstrap';



export default function  Emailver(props){


    const { currentUser } = useContext(AuthContext);
    

    const [inputs, setInputs] = useState({
        otp: ""
        
      });


    const handleChange = (e) => {
        setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
      };
    
      const navigate = useNavigate();
    
      const { passrest } = useContext(AuthContext);


    const handleClick = async (e) => {
        e.preventDefault();
        try {
          await passrest(inputs);
          navigate("/changepass")
        } catch (err) {
          
        }
      };
  
 const check = props.inputs
  console.log(check)
return (
    <>
 <Row style={{justifyContent:"center",}}>
    <Col Col sm={8}>
     <Card style={{padding:"5rem", marginTop:"10%", background: "linear-gradient(rgba(134 ,214 ,194, 0.77), rgba(13, 55, 13, 0.76))"}}>
        <CardBody style={{textAlign:"center", color:"white"}}>
            <img src={logo} style={{width:"170px", height:"44px", marginBottom:"1rem"}}/>
            <h4>
        To complete the verification process,
         please check your email: {currentUser[0].email} inbox for the One-Time Password
          (OTP) we've sent. Enter the OTP to verify your Email  <br/>
             </h4>
                <input type="text" required 
               name="otp"
               onChange={handleChange}
               
               style={{width:"80%", marginTop:"2rem", padding:"7px", borderRadius:"5px"}}/>

              <input className="inps"  type="submit" value="verify" onClick={handleClick} />
        </CardBody>
     </Card>
    </Col>
 </Row>


    
    </>
)

}