import "./mirror.css"




export default function optioncopy(){

return (
<>


<div className="page-main">
        <section className="page-m-intro extr-hero">
            <div className="page__container extra-inner-bg"></div>
            <div className="page__container" style={{position: "absolute",zIndex: 10}}>
                <div className="page-m-intro__content">
                    <div className="page-m-intro__background page-m-intro__background--ltr" style={{position: "absolute"}}>
                        <h1 data-trans="about_page_intro_title" className="page-c-title page-m-intro__title">Option Copy Trading
                        </h1>
                        <p className="page-m-intro__description text" data-trans="about_page_intro_description">Gmcstockcopytrading
                            Trade has been
                            sharing financial freedom with traders since 2014. In a continuous effort to give traders a more
                            comfortable and safe experience, its experts have been improving the platform ensuring traders
                            can
                            enjoy and make use of that freedom to trade whenever and wherever they like.</p>
                    </div>
                </div>
        </div></section>
        <section className="p-home-m-seo-become-a-trader p-home__section p-home-m-seo-become-a-trader--ltr" style={{marginTop: "50px"}}>
            <div className="page__container">
                <div className="p-home-m-seo-become-a-trader__content-wrap">
                    <h2 className="com-c-title p-home-m-seo-become-a-trader__title" data-trans="become_a_trader_title">Option
                        Copy Trading
                    </h2>
                    <div className="p-home-m-seo-become-a-trader__content" style={{display: "flex", height:"850px"}}>
                        <div className="p-home-m-seo-become-a-trader__item-wrap" style={{maxWdth: "50%",width: "50%",height: "100%"}}>
                            <div className="p-home-m-seo-become-a-trader__item-wrap-content">
                                <h2 className="com-c-title p-home-m-seo-become-a-trader__item-head" data-trans="become_a_trader_item_1_head">Copy trading is replicating another trader’s
                                    positions using social platforms, automated tools and signals</h2>
                                <p className="p-home-m-seo-become-a-trader__item-text" data-trans="become_a_trader_item_1_text_1">
                                    Copy trading is a type of trading where you copy the trades performed by another, more
                                    experienced trader. It can be manual, semi-automatic or fully automatic.</p>
                                <p className="p-home-m-seo-become-a-trader__item-text" data-trans="become_a_trader_item_1_text_2">
                                    Copy trading allows individuals to automatically copy another trader’s positions when
                                    they are opened or closed. Experienced traders communicate their positions using signals
                                    via social networks or forums, where followers can copy the methods.</p>
                                <p className="p-home-m-seo-become-a-trader__item-text" data-trans="become_a_trader_item_1_text_2">
                                    Traders can copy positions in many markets, including forex, stocks and CFDs. You can
                                    also copy trades on popular crypto coins, including Bitcoin (BTC) or major precious
                                    metals such as Gold or Platinum.</p>
                                <p className="p-home-m-seo-become-a-trader__item-text" data-trans="become_a_trader_item_1_text_2">
                                    Copy trading can be a good way to earn a profit and make you rich, but it is important
                                    to understand that you will not become rich overnight. If you try to become rich too
                                    fast, you will have to copy very high-risk trades, and you will likely end up losing
                                    your money. If you use copy trading to build wealth slowly, you will have a fair chance
                                    of becoming a millionaire in due time.</p>
                            </div>
                        </div>
                        <div className="mol" >
                        </div>
                    </div>
                </div>
        </div></section>
        <section className="p-home-m-seo-become-a-trader p-home__section p-home-m-seo-become-a-trader--ltr">
            <div className="page__container">
                <div className="p-home-m-seo-become-a-trader__content-wrap">
                    <h2 className="com-c-title p-home-m-seo-become-a-trader__title" data-trans="become_a_trader_title">Pros and
                        Cons
                    </h2>
                    <div className="p-home-m-seo-become-a-trader__content" style={{display: "flex"}}>
                        <div className="p-home-m-seo-become-a-trader__item-wrap" style={{maxWidth: "50%",width: "50%",height: "100%"}}>
                            <div className="p-home-m-seo-become-a-trader__item-wrap-content">
                                <h2 className="com-c-title p-home-m-seo-become-a-trader__item-head" data-trans="become_a_trader_item_1_head">Pros</h2>
                                <p className="p-home-m-seo-become-a-trader__item-text" data-trans="become_a_trader_item_1_text_1">
                                    Authorised practice – copy trading is generally recognised by key regulatory frameworks,
                                    including CySEC, ESMA, MiFID and the FCA. Choosing a licensed and reputable broker will
                                    ensure your funds are safe and not exposed to scams.</p>
                                <br/>
                                <p class="p-home-m-seo-become-a-trader__item-text" data-trans="become_a_trader_item_1_text_2">
                                    Portfolio diversification – traders can gain exposure to opportunities or trends that
                                    they wouldn’t usually consider without the help of another trader’s expertise.</p>
                            </div>
                        </div>
                        <div className="p-home-m-seo-become-a-trader__item-wrap" style={{maxWidth: "50%",width: "50%",height: "100%"}}>
                            <div className="p-home-m-seo-become-a-trader__item-wrap-content">
                                <h2 className="com-c-title p-home-m-seo-become-a-trader__item-head" data-trans="become_a_trader_item_1_head">Cons</h2>
                                <p className="p-home-m-seo-become-a-trader__item-text" data-trans="become_a_trader_item_1_text_1">
                                    Risk – the risks can be high even if you choose an experienced trader to copy. If a
                                    strategy is unsuccessful, the risk will also translate onto a follower’s account and can
                                    result in a financial loss.</p>
                                <br/>
                                <p className="p-home-m-seo-become-a-trader__item-text" data-trans="become_a_trader_item_1_text_2">
                                    Control – one of the main disadvantages is the lack of control a trader will have once
                                    they begin copying an account; traders are essentially entrusting their portfolio to a
                                    stranger.</p>
                            </div>
                        </div>
                    </div>
                </div>
        </div></section>
        <section className="p-home-m-seo-become-a-trader p-home__section p-home-m-seo-become-a-trader--ltr" style={{marginTop: "50px"}}>
            <div className="page__container">
                <div className="p-home-m-seo-become-a-trader__content-wrap">
                    <h2 className="com-c-title p-home-m-seo-become-a-trader__title" data-trans="become_a_trader_title">Risk
                    </h2>
                    <div className="p-home-m-seo-become-a-trader__content">
                        <div className="p-home-m-seo-become-a-trader__item-wrap" style={{maxWidth: "50%",width: "50%",height: "100%"}}>
                            <div className="p-home-m-seo-become-a-trader__item-wrap-content">
                                <p className="p-home-m-seo-become-a-trader__item-text" data-trans="become_a_trader_item_1_text_1">
                                    All types of trading are associated with risk. You always risk losing part or all of
                                    your investment. Never invest money you can not afford to lose. The risk associated with
                                    copy trading depends on the type of asset or security you choose to copy trade. Copying
                                    the trades of a trader that trades high-risk assets such as Forex, Crypto or binary
                                    options will be high risk. Copying the trades of a trader that trades low-risk
                                    securities such as blue chip stocks will be low risk.</p>
                                <p className="p-home-m-seo-become-a-trader__item-text" data-trans="become_a_trader_item_1_text_2">
                                    You should follow a trader that trades using a risk profile that you feel comfortable
                                    with. Many platforms will give you a risk indicator for each trader you can choose to
                                    copy, but it is always best to manually inspect their trade history and see if you feel
                                    comfortable with their trading strategies and risk profile. When in doubt, choose a
                                    broker with a lower-risk profile. You can increase your risk exposure later on, but if
                                    you choose a high-risk strategy and lose money, it will be too late to move that money
                                    to a lower-risk option since the money is already lost.</p>
                                <p className="p-home-m-seo-become-a-trader__item-text" data-trans="become_a_trader_item_1_text_2">
                                    A common beginner’s mistake is only copying one trader. A profitable trading history
                                    does not guarantee future returns. All traders can produce a period of poor returns or
                                    losses. It is always best to split your money and follow more than one trader. This will
                                    give you better diversification and will allow you to earn a profit even if one trader
                                    has a bad month or year. Diversification will reduce the risk associated with all types
                                    of trading and is one of the most basic types of risk management. All beginner traders
                                    should try to diversify their investment portfolio.</p>
                            </div>
                        </div>
                    </div>
                </div>
        </div></section>


        <section style={{textAlign:"center"}} className="p-home-m-payments p-home__section">
            <div className="page__container">
                 
                <p  style={{color:"#8fd78db5"}} className="com-c-description p-home-m-payments__desc" data-trans="home_payments_desc">Quick and easy
                    deposits and withdrawals</p>
                <ul data-test="psp_icons_list" class="p-home-m-payments__list"></ul>
            </div>
        </section>
    </div>


</>



)

}
