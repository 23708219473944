import React, { useEffect } from 'react';

const GoogleTranslate = () => {

  useEffect(() => {
    // Check if the callback function is already defined
    if (typeof window.googleTranslateElementInit !== 'function') {
      // Define the callback function
      window.googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element');
      };

      // Load the Google Translate script dynamically
      const script = document.createElement('script');
      script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
      script.async = true;
      document.head.appendChild(script);
    }
  }, []);

  return (
     <div id="google_translate_element" style={{height:"2rem",marginLeft:"1rem", marginRight:"1rem"}}></div> 
  );
};

export default GoogleTranslate;

