import React, { useState } from 'react';
import Modal from 'react-modal';

const ImagePreview = ({ imageURL, imageURL2 }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div>
      <img
        src={imageURL}
        alt="Preview"
        style={{ cursor: 'pointer', height:"20px" }}
        onClick={openModal}
      />

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
          },
          content: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: '100%',
            maxHeight: '100%',
            margin: 'auto',
          },
        }}
      >
         <div style={{margin:"1.5rem"}}>
      <img
          src={imageURL}
          alt="Preview"
          style={{ maxWidth: '100%', maxHeight: '100%' }}
        />
        </div>

        
    <div style={{margin:"1.5rem"}}>
       <img
          src={imageURL2}
          alt="Preview"
          style={{ maxWidth: '100%', maxHeight: '100%' }}
        />
         </div>
        
        <button onClick={closeModal}>Close</button>
      </Modal>
    </div>
  );
};

export default ImagePreview;
