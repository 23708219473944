// import { useDispatch,useSelector } from 'react-redux';
import { BrowserRouter as Router , Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
// import Dashboard from './pages/userDashboard/UserDashbord';
import Mainlayout from './Mainlayout';
import Register from './pages/reg/Register';
import Login from './pages/Login/Login';
import Admin from './pages/admin/Admin';
import UserDashboard from './UserDashboard';
import InvestorTransaction from "./components/investorTransactions/InvestorTransaction";
import Homepagelayout from './HomepageLayout';
import Kpackp from './pages/adress/adress';
import Kpackp2 from './pages/adress2/adress';
import Kpackp3 from './pages/adress3/adress';
import Kpackp4 from './pages/adress4/adress';
import Cert from './pages/certificate/certificate';
import { useEffect, useState } from 'react';
import { makeRequest } from "./axios";
import Otpverify from './pages/otpverify/otp';
import Passr from './pages/Login/passwordre';
import Emailver from './pages/Login/emailver';
import Changepass from './pages/Login/changepass';
import { AuthContext } from './authcontext/authContext';
import { useContext} from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';



function App() {
const [userInfo, setUserInfo] = useState(null);
const [linfo, setLinfo] = useState(0);
const { currentUser } = useContext(AuthContext);
  

const isLogged = currentUser?.is_admin === 1

  return ( 
    <HelmetProvider>

    <Router>
        <Routes>
        
        <Route path='/' element={<Homepagelayout /> } /> 
        <Route path='/235676_certificate' element={<Cert /> } /> 
        <Route path="*" element={<Mainlayout />} />
        <Route path="/emailverify" element={<Otpverify />} />
        <Route path="/changepass" element={<Changepass />} />
      
        <Route path="/emailver" element={<Emailver />} />
        <Route path="/forgottenpassword" element={<Passr />} />
          <Route path='/dashboard' element={currentUser ? <UserDashboard  /> : <Login/>}/>
          
          
          
          <Route path='/register' element={<Register />} />
          <Route path='/login' element={<Login />} />
          <Route path='/adress' element={<Kpackp />} />
          <Route path='/adressETH' element={<Kpackp2 />} />
          <Route path='/adressBTCH' element={<Kpackp3 />} />
          <Route path='/adressUSDT' element={<Kpackp4 />} />
          <Route path='/admin' element={ isLogged ? <Admin /> : <Login/>} />
          <Route path='/alltransactions/:useId' element={<InvestorTransaction />} />
         
        </Routes>
     </Router>
     </HelmetProvider>
  );
}





export default App;


// App.js


// // App.js
// import React, { useContext } from "react";
// import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import Dashboard from './pages/userDashboard/UserDashbord';
// import Mainlayout from './Mainlayout';
// import Register from './pages/reg/Register';
// import Login from './pages/Login/Login';
// import Admin from './pages/admin/Admin';
// import  AuthContext  from './authcontext/authContext';

// const App = () => {
//   const { currentUser } = useContext(AuthContext);
  

//   return (
//     <Router>
//       <Routes>
//         <Route path="/" element={<Mainlayout />}>
//           <Route path="/register" element={<Register />} />
//           <Route path="/login" element={<Login />} />
//           <Route path="/admin" element={<Admin />} />
//         </Route>
//       </Routes>
//     </Router>
//   );
// };

// export default App;
